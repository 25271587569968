import {
  Container,
} from 'react-bootstrap'
import { Dispatch, bindActionCreators } from 'redux'
import { assetURL } from '~/utils'
import { withI18n, withUser } from '~/hocs'

import AlertRedirectError from '../common/AlertRedirectError'
import AppContext from '~/context'
import React, { useEffect, useState } from 'react'
import config from '~/config'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { logout } from '~/store/auth/actions'
import { makeSelectMsgAuthErr } from '~/store/auth/selector'
import { routes } from '~/router'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import AuthService from '~/services/AuthService'
import googleOneTap from 'google-one-tap'
import HeaderLogo from '../navbars/HeaderLogo'
import HeaderSearch from '../navbars/HeaderSearch'
import HeaderRight from '../navbars/HeaderRight'
import HeaderRightSmall from '../navbars/HeaderRightSmall'
import HeaderNavBar from '../navbars/HeaderNavBar'
import CountDownEvent from './countdown/CountDownEvent'
import Link from 'next/link'
import moment from 'moment'

function NavBar({
  lng,
  t,
  profile,
  isAuthen,
  isPremium,
  msgAuthErr,
  headerData,
  isCreator,
}: any) {
  const [isCollappseEnter, setIsCollappseEnter] = React.useState(false)
  const [isCollappseCreatorEnter, setIsCollappseCreatorEnter] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const context = React.useContext(AppContext)
  const timestamp = Date.now()
  const { data: nextAuthSession, status: nextAuthStatus } = useSession()
  const { asPath } = useRouter()
  const [showCategoryHeader, setShowCategoryHeader] = useState(false)
  const [showSettingAccountHeader, setShowSettingAccountHeader] = useState(false)
  const [showInfoSideBar, setShowInfoSideBar] = useState(false)
  const isCreatorPages = asPath.indexOf('/creator') === 0 && asPath.indexOf(routes.creator.reg.href) === -1
  const getRandomNumber = () => Math.random() < 0.5
  const random = getRandomNumber()

  useEffect(() => {
    // Auto SSO login
    window.addEventListener('message', async function (event) {
      if (event.origin === config.sso.url && event.data) {
        try {
          const ssoTokenData = JSON.parse(event.data)
          AuthService.getSSOUserInfo({ session_id: ssoTokenData.sso_logged }).then((res: any) => {
            if (res.sessionUser) {
              // run loader / spinner
              setIsLoading(true)
              window.location.href = `${routes.api.auth.signin.href}/?jwtToken=${res.sessionUser.token}&redirect=${window.location.href}&autoLogin=true`
              // window.location.href = `${routes.api.auth.signin.href}/?jwtToken=${res.sessionUser.token}&redirect=${window.location.href}&autoLogin=true&ssoToken=${ssoTokenData.sso_logged}`
            } else {
              const iframe = (document.getElementById('ssoAutoLoginIframe') as HTMLIFrameElement).contentWindow
              iframe?.postMessage(JSON.stringify({ delete_sso_logged: ssoTokenData.sso_logged }), '*')
            }
          })
        } catch (error) {
          console.log(error)
        }
      }
    })
  }, [])

  const onNavCollapseEnter = () => {
    const btnClose: any = document.getElementsByClassName('btn-mobile-close')
    btnClose[0].onclick = () => {
      setIsCollappseEnter(false)
    }
    setIsCollappseEnter(true)
  }
  const onNavCollapseEnterCreator = () => {
    const btnClose: any = document.getElementsByClassName('btn-mobile-creator-close')
    btnClose[0].onclick = () => {
      setIsCollappseCreatorEnter(false)
    }
    setIsCollappseCreatorEnter(true)
  }
  const options = {
    client_id: process.env.GOOGLE_CLIENT_ID_ONETAP, // required
  }

  const fixRidiculousZindex = () => {
    const elements = document.querySelectorAll('*');
    elements.forEach(element => {
      const computedStyle = window.getComputedStyle(element);
      if (computedStyle.zIndex && parseInt(computedStyle.zIndex) > 9999) {
        element.style.zIndex = '12';
      }
    });
  }

  useEffect(() => {
    const timerId = setTimeout(() => {
      fixRidiculousZindex();
    }, 1000);

    return () => clearTimeout(timerId);
  }, []);

  useEffect(() => {
    if (!isAuthen && !isCreatorPages) {
      googleOneTap(options, (response) => {
        setIsLoading(true)
        window.location.href = `${config.sso.url}/auth/googleonetap?idToken=${response.credential}&serviceURL=${config.url.location}/api/auth/signin?redirect=${window.location.href}`
      })
    }
  }, [])
  return (
    <>
      {isLoading && <div className="loading-fullscreen">Loading...</div>}
      {profile?.session_id && <iframe sandbox="allow-scripts allow-same-origin" width="0" height="0" style={{ display: 'none' }} id="ssoAutoLoginIframe"
        src={`${config.sso.url}/share-auth?token=${profile?.session_id ?? ''}&from=${config.url.location}&t=${timestamp}`} hidden />}
      <HeaderNavBar lng={lng} headerData={headerData} onNavCollapseEnter={onNavCollapseEnter}
        isCollappseEnter={isCollappseEnter} showCategoryHeader={showCategoryHeader} setShowCategoryHeader={setShowCategoryHeader} />
      {asPath.indexOf(routes.creator.signin.href) !== 0 && !isPremium && !isCreatorPages && <CountDownEvent />}
      {
        asPath.indexOf(routes.creator.signin.href) === 0 || asPath.indexOf(routes.creator.regDone.href) === 0
          ? <></>
          : <>
            <div className="navbar-container-sticky bg-light border-bottom">
              <Container>
                <div className="navbar-logo-container my-2 d-flex align-items-center justify-content-between">
                  <div className="header-logo d-flex">
                    <div className="d-flex align-items-center justify-content-between">
                      <HeaderLogo lng={lng} />
                    </div>
                  </div>
                  {
                      isCreatorPages && nextAuthStatus === 'authenticated'
                        ? (
                          <>
                            <div className="flex-center flex-grow-1 h-100 mt-0 mt-md-2 d-none d-md-flex" style={{marginLeft: "9%"}}>
                              <Link href="#">
                                <a style={{ width: '100%' }}>
                                  <img src={assetURL('asset/image/creator/banner11pt.jpeg')} className="mw-100" />
                                </a>
                              </Link>
                            </div>
                          </>
                        )
                        : (<></>)}
                  <div className="flex-grow-1 header-search">
                    {
                      headerData && headerData.has_search && <HeaderSearch asPath={asPath} isPremium={isPremium} isAuthen={isAuthen} nextAuthStatus={nextAuthStatus}
                        headerData={headerData} context={context} profile={profile} />
                    }
                  </div>
                  <HeaderRightSmall
                    lng={lng}
                    isAuthen={isAuthen}
                    nextAuthStatus={nextAuthStatus}
                    isCreatorPages={isCreatorPages}
                    onNavCollapseEnterCreator={onNavCollapseEnterCreator}
                    isCollappseCreatorEnter={isCollappseCreatorEnter}
                    isPremium={isPremium}
                    profile={profile}
                    setShowInfoSideBar={setShowInfoSideBar}
                    showInfoSideBar={showInfoSideBar}
                    nextAuthSession={nextAuthSession}
                    showSettingAccountHeader={showSettingAccountHeader}
                    setShowSettingAccountHeader={setShowSettingAccountHeader}
                    setIsCollappseCreatorEnter={setIsCollappseCreatorEnter}
                  />
                  <HeaderRight lng={lng} isPremium={isPremium} isAuthen={isAuthen} nextAuthStatus={nextAuthStatus} nextAuthSession={nextAuthSession} profile={profile} />
                </div>
                <div className="mb-2 d-none header-search-sm">
                  {
                    headerData && headerData.has_search && <HeaderSearch asPath={asPath} isPremium={isPremium} isAuthen={isAuthen} nextAuthStatus={nextAuthStatus}
                      headerData={headerData} context={context} profile={profile} />
                  }
                </div>
              </Container>
            </div>
            {asPath == '/' && nextAuthStatus !== 'authenticated' && ((!isPremium || profile?.premium_info?.pause_end_date && moment(profile?.premium_info.pause_end_date, "YYYY-MM-DD").valueOf() > moment().valueOf()) && <div className="d-block d-xl-none">
                <div className="bg-f1f1f1 text-center p-1 font-size-12 premium-header-mainpage">
                  <a href={config.premiumUrl} target="_blank" className="ac-text-blue" data-toggle="tooltip" title="" data-original-title="プレミアム会員登録1">
                    <img className="mw-100 lazy align-middle mt-n1 loaded" alt="プレミアム会員登録" height="15" src="https://jp.static.photo-ac.com/assets/img/responsive/ico_premium.png" data-ll-status="loaded" />
                    <span className="align-middle">プレミアム会員登録</span>
                  </a>
                  <span className="align-middle">で14個の特典をゲット</span>
                </div>
              </div>)}
          </>
      }
      {<AlertRedirectError msg_err={t(msgAuthErr)} />}
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  msgAuthErr: makeSelectMsgAuthErr(),
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  logout,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withI18n(withUser(NavBar)))
