import api, { genHeader } from './API'
import api_ext from './API-EXT'
import Axios, { CancelToken, AxiosResponse } from 'axios'
import config from '~/config'
import { routes } from '~/router'

import { UPLOAD_STATUS } from '~/utils/constants'

export default {
  creatorReg({ user_data }: any): Promise<AxiosResponse<any>> {
    return api.post('/user/creatorReg', {
      user_data,
    })
  },
  creatorLogin({ email, password }: any): Promise<AxiosResponse<any>> {
    return api.post('/auth/creatorLogin', {
      email,
      password,
    })
  },
  creatorLoginAdmin({admin_token, user_id}:any): Promise<AxiosResponse<any>> {
    return api_ext.post('/auth/creatorLoginAdmin', {
      user_id
    },genHeader(admin_token))
  },
  creatorVerifyEmail(data: any): Promise<AxiosResponse<any>> {
    return api.post('/auth/creatorVerifyEmail', data)
  },
  getCreatorInfo({ email }: any): Promise<AxiosResponse<any>> {
    return api.get('/user/getCreatorInfo', {
      params: {
        email,
      },
      ...genHeader(config.apiSecret),
    })
  },
  getCreatorInfoByID({ id }: any): Promise<AxiosResponse<any>> {
    return api_ext.get('/user/getCreatorInfoByID', {
      params: {
        id,
      },
    })
  },
  verifyToken({ jwt_token, sso_token }: any): Promise<AxiosResponse<any>> {
    return api.post('/auth/verify-token', {
      jwt_token,
      sso_token,
    })
  },
  verifyUserInfo({ user_data }: any): Promise<AxiosResponse<any>> {
    return api.post('/auth/verify-user', {
      user_data,
    })
  },
  verifySSOToken({ sso_token }: any): Promise<AxiosResponse<any>> {
    return api.get(`${config.sso.url}/verifytoken`, {
      params: {
        ssoToken: sso_token,
      },
      ...genHeader(process.env.SSO_APP_TOKEN),
    })
  },
  getSSOUserInfo({ session_id }: any): Promise<AxiosResponse<any>> {
    return api.get(`${config.sso.url}/get-session-user`, {
      params: {
        globalSessionToken: session_id,
      },
    })
  },
  getUserInfoBySession({ session_id, is_full }: any): Promise<AxiosResponse<any>> {
    return api.get('/user/info-by-session', {
      params: {
        session_id,
        is_full,
      },
    })
  },
  getUserInfo({ id, username }: any): Promise<AxiosResponse<any>> {
    return api.get('/user/info', {
      params: {
        id,
        username,
      },
      ...genHeader(config.apiSecret),
    })
  },
  update(token: string, { username, firstname, lastname, address, phone, dob, kyc_s3_keys, occupation, province, zipcode }: any): Promise<AxiosResponse<any>> {
    return api.put('/user', {
      firstname, lastname, username, address, phone, dob, kyc_s3_keys, occupation, province, zipcode,
    }, genHeader(token))

    // return new Promise(resolve => setTimeout(() => resolve({ data: { username, address, phone, dob, kyc_s3_keys } }), 2000))
  },
  // updateCreator(token: string, { email, firstname, lastname, address, phone, dob, occupation
  //   , province, zipcode, municipalities, building_name, update_password, nickname, blog_title, blog_url, twitter_title, twitter_url
  // ,facebook_title, facebook_url,instagram_title,instagram_url}: any): Promise<AxiosResponse<any>> {
  //   return api.put('/creator', {
  //     firstname, lastname, email, address, phone, dob, occupation, province, zipcode, municipalities, building_name, update_password,nickname,
  //     blog_title, blog_url, twitter_title, twitter_url,facebook_title, facebook_url,instagram_title,instagram_url
  //   }, genHeader(token))
  // },
  updateCreator(token: string, data: any): Promise<AxiosResponse<any>> {
    return api.put('/creator', data, genHeader(token))
  },
  updateLoginAt(token: string, data: any): Promise<AxiosResponse<any>> {
    return api.post('/user/updateLoginAt', data, genHeader(token))
  },
  updateBitcoinAddress(token: string, { bitcoin_address }: any): Promise<AxiosResponse<any>> {
    return api.put('/bitcoin', {
      bitcoin_address,
    }, genHeader(token))
  },
  deleteTags(token: string, { creatorId, tagDelete, lng }: any) {
    return api.delete('/tag', {
      data: {
        creatorId, tagDelete, lng,
      },
      ...genHeader(token),
    })
  },
  leaveAccount(token: string, { email, password }: any): Promise<AxiosResponse<any>> {
    return api.post('/account/leave', {
      email,
      password,
      leaveUrl: `${config.url.location}${routes.creator.account.leave.href}`,
    }, genHeader(token))
  },
  leaveComplete(data: any): Promise<AxiosResponse<any>> {
    return api.post('/account/leaveComplete', data)
  },
  resetPasswordRequest(data: any): Promise<AxiosResponse<any>> {
    return api.post('/creator/resetPasswordRequest', {
      resetPasswordUrl: `${config.url.location}${routes.creator.resetPassword.href}`,
      ...data,
    })
  },
  resetPassword(data: any): Promise<AxiosResponse<any>> {
    return api.post('/creator/resetPassword', data)
  },
  validateResetPasswordRequest(data: any): Promise<AxiosResponse<any>> {
    return api.post('/creator/validateResetPasswordRequest', data)
  },
  subscribeFan({ creatorId, fanUserId }:any): Promise<AxiosResponse<any>> {
    return api_ext.post('/fan/subscribe', {
      creatorId,
      fanUserId,
    })
  },
  getSubscriber({ creatorId, limit, page }: any): Promise<AxiosResponse<any>> {
    return api_ext.get('/fan/get', {
      params: {
        creatorId,
        limit,
        page,
      },
    })
  },
  createComment({ creatorId, userId, parentCommentId, content, videoId, videoTitle, urlVideo, urlCreator, nickname, isCreatorOfThisVideo, isCreatorOfThisPage }:any): Promise<AxiosResponse<any>> {
    return api_ext.post('/comment', {
      creatorId,
      userId,
      parentCommentId,
      content,
      videoId,
      videoTitle,
      urlVideo,
      urlCreator,
      nickname,
      isCreatorOfThisVideo,
      isCreatorOfThisPage
    })
  },
  getComment({ creatorId, limit, videoId }: any): Promise<AxiosResponse<any>> {
    return api_ext.get('/comment/get', {
      params: {
        creatorId,
        videoId,
        limit,
      },
    })
  },
  getCommentCreator({ creatorId, limit, page }: any): Promise<AxiosResponse<any>> {
    return api_ext.get('/showCommentCreator', {
      params: {
        creatorId,
        limit,
        page,
      },
    })
  },
  getFollowingCreator({ creatorId, limit, page, sortBy }: any): Promise<AxiosResponse<any>> {
    return api_ext.get('/showFollowingCreator', {
      params: {
        creatorId,
        limit,
        page,
        sortBy,
      },
    })
  },
  deleteUserFan(token: string, { creatorId, isCreator }: any): Promise<AxiosResponse<any>> {
    return api_ext.delete('/deleteUserFan', {
      data: {
        creatorId,
        isCreator,
      },
      ...genHeader(token),
    })
  },
  createReply({ isCreatorOfThisVideo, isCreatorOfThisPage, userId, parentCommentId, content, videoId, videoTitle, urlVideo, urlCreator, nickname, creatorId }:any): Promise<AxiosResponse<any>> {
    return api_ext.post('/reply', {
      isCreatorOfThisVideo,
      userId,
      parentCommentId,
      isCreatorOfThisPage,
      content,
      videoId,
      videoTitle,
      urlVideo, 
      urlCreator,
      nickname,
      creatorId
    })
  },
  updateComment({ id, content }:any): Promise<AxiosResponse<any>> {
    return api_ext.put('/updateComment', {
      id,
      content,
    })
  },
  hideComment(token: string, id: any): Promise<AxiosResponse<any>> {
    return api_ext.put('/hideComment', {
      id,
    }, genHeader(token))
  },
  deleteComment(id:any): Promise<AxiosResponse<any>> {
    return api_ext.delete('/deleteComment', {
      data: {
        id,
      },
    })
  },
  deleteRecommendVideoProfile(token: string, { video_id }: any): Promise<AxiosResponse<any>> {
    return api_ext.delete('/deleteRecommendVideoProfile', {
      data: {
        video_id,
      },
      ...genHeader(token),
    })
  },
  updateProfile(token: string, { nickname, blog_title, blog_url, twitter_title, twitter_url
    , facebook_title, facebook_url, instagram_title, instagram_url, comment, camera, avatar }: any): Promise<AxiosResponse<any>> {
    return api_ext.put('/updateProfile', {
      nickname, blog_title, blog_url, twitter_title, twitter_url, facebook_title, facebook_url, instagram_title, instagram_url, comment, camera, avatar,
    }, genHeader(token))
  },
  createRecommendVideoProfile(token: string, { video_id }: any): Promise<AxiosResponse<any>> {
    return api_ext.post('/createRecommendVideoProfile', {
      video_id,
    }, genHeader(token))
  },
  beforeUpload(token: string, { name, mime_type, size, type, width, height }: any, cancelToken: CancelToken | undefined) {
    return api.post('/upload', {
      name,
      mime_type,
      size,
      type,
      width,
      height,
    }, {
      ...genHeader(token),
      cancelToken,
    }).then(res => res.data)
  },
  uploadAvatar(token: string, uploads: any, cbProgress: Function) {
    return Promise.all(
      uploads.map((u: any) => {
        const base = {
          ...u,
          request_upload: true,
        }
        const cancelTokenSource = Axios.CancelToken.source()
        cbProgress({
          ...base,
          status: UPLOAD_STATUS.PENDING,
          cancelTokenSource,
        })
        return this.beforeUpload(token, u, cancelTokenSource.token)
          .then((res: any) => {
            // cancelTokenSource = Axios.CancelToken.source()
            base.preview = res.preview
            base.key = res.key
            const options = {
              headers: {
                'Content-Type': u.mime_type,
              },
              cancelToken: cancelTokenSource.token,
              onUploadProgress: (progressEvent: any) => {
                const { loaded, total } = progressEvent
                const percent = Math.floor((loaded * 100) / total)
                cbProgress({
                  id: res.id,
                  ...base,
                  percent,
                  loaded,
                  total,
                  status: UPLOAD_STATUS.UPLOADING,
                  cancelTokenSource,
                })
              },
            }
            return Axios.put(res.url, u.file, options)
              .then(() => {
                cbProgress({
                  id: res.id,
                  ...base,
                  request_upload: false,
                  // status: UPLOAD_STATUS.UPLOADED,
                  percent: 100,
                  status: UPLOAD_STATUS.UPLOADED,
                  cancelTokenSource,
                })
              })
              .catch(err => {
                if (Axios.isCancel(err)) {
                  cbProgress({
                    id: res.id,
                    ...base,
                    request_upload: false,
                    status: UPLOAD_STATUS.CANCELLED,
                  })
                } else {
                  cbProgress({
                    id: res.id,
                    ...base,
                    request_upload: false,
                    status: UPLOAD_STATUS.ERR,
                  })
                }
              })
          })
          .catch(err => {
            cbProgress({
              ...base,
              request_upload: false,
              status: UPLOAD_STATUS.ERR,
              err: err,
            })
          })
      }),
    )
  },
  checkCreatorLogin({ email }: any): Promise<AxiosResponse<any>> {
    return api_ext.post('/checkCreatorLogin', {
      email,
    })
  },
  checkNickname({ user_id, nickname }: any): Promise<AxiosResponse<any>> {
    return api_ext.post('/checkNickname', {
      user_id,
      nickname,
    })
  },
  updateLoginAtUser({ user_id }: any): Promise<AxiosResponse<any>> {
    return api_ext.post('/checkNickname', {
      user_id,
    })
  },
}
