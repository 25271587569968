import React from 'react'
import { withI18n } from '~/hocs'
import { assetURL } from '~/utils'
import { NextPage } from 'next'
import { AppCommonProps } from 'types/app'
import Link from 'next/link'

const HeaderLogo: NextPage<AppCommonProps> = ({ t, lng }) => {
    return (
        <div className="d-flex align-items-center">
            <Link href="/" locale={lng}>
                <img className="hover" src={assetURL('asset/logo/video_ac_250x57.png')} alt="logo" height="25" />
            </Link>
            <div className="ml-2 d-block d-flex align-items-center logo-description" style={{
                lineHeight: '100%',
            }}>
                <div className="logo-description-detail cursor-pointer">
                    <div style={{ lineHeight: 1.1, fontSize: '12px', color: "#333333" }}>
                        <Link href="/">
                            <span>動画ACは動画素材が無料！</span>
                        </Link>
                    </div>
                    <div style={{ lineHeight: 1.6, width: 'max-content', fontSize: '12px', color: "#333333" }}>
                        <Link href="/">
                            <span>商用利用もOK！</span>
                        </Link>
                    </div>
                </div>
                {/* <div className="button-beta text-secondary">
                    Beta
                </div> */}
            </div>
        </div>
    )
}

export default withI18n(HeaderLogo)