import React from 'react'
import { SearchPanel } from '~/components/common'

const AppContext = React.createContext(null)

export default AppContext

export const contextValue = {
  SearchPanel,
}
