import { AppCommonProps } from 'types/app'
import React from 'react'
import { withI18n } from '~/hocs'

const CountDownEventSmallGreen = ({ backgroundColor, colorText, days, hours, minutes, seconds, renderDigits, premiumURL }: AppCommonProps) => {
  return (
    <a href={premiumURL} className={`${backgroundColor} ac-py-1 d-block ac-d-md-none text-decoration-none`}>
      <p className={`text-center font-size-18 font-weight-bold mb-1 ${colorText}`}>【ハロウィンキャンペーン！10月31日まで】</p>
      <p className="d-flex align-items-end justify-content-center mb-2">
        <span className={`ac-heading-6 align-bottom mr-1 ${colorText}`}>終了まで</span>
        <span className={`ac-heading-3 lh-1 align-bottom text-ff8f00`}>{days}</span>
        <span className={`${colorText}`} style={{fontSize: "16px"}}>日</span>
        <span className={`ac-heading-3 lh-1 text-ff8f00`}>{renderDigits(hours)}</span>
        <span className={`${colorText}`} style={{fontSize: "16px"}}>時間</span>
        <span className={`ac-heading-3 lh-1 text-ff8f00`}>{renderDigits(minutes)}</span>
        <span className={`${colorText}`} style={{fontSize: "16px"}}>分</span>
        <span className={`ac-heading-3 lh-1 text-ff8f00`}>{renderDigits(seconds)}</span>
        <span className={`${colorText}`} style={{fontSize: "16px"}}>秒
        </span></p>
      <p className="d-flex align-items-center justify-content-center mb-2">
        <span className={`ac-heading-5 lh-1 mr-1 ${colorText}`} style={{ lineHeight: '0.8', fontWeight: '500' }}>プレミアム会員が</span>
        <span className={`ac-heading-5 lh-1 font-weight-bold text-ff8f00`} style={{fontSize: "28px"}}>35</span>
        <span className={`ac-heading-5 lh-1 font-weight-bold text-ff8f00`} style={{fontSize: "28px"}}>%OFF</span>
      </p>
      <p className={`d-flex align-items-center justify-content-center mb-0 ${colorText}`} style={{ fontSize: '11px' }}>
      ※法人プラン会員はキャンペーン適応外となります。
      </p>
    </a>
  )
}
export default withI18n(CountDownEventSmallGreen)
