import React from 'react'
import { withI18n } from '~/hocs'
import { assetURL } from '~/utils'
import { NextPage } from 'next'
import { AppCommonProps } from 'types/app'
import Link from 'next/link'
import { routes } from '~/router'
import { Navbar } from 'react-bootstrap'
import { signOut } from 'next-auth/react'
import classNames from 'classnames'


const HeaderNavBarCreator: NextPage<AppCommonProps> = ({ t, lng, isCollappseCreatorEnter, showSettingAccountHeader, setShowSettingAccountHeader, setIsCollappseCreatorEnter }) => {
    return (
        <Navbar expand="md" className="navbar-container-tophead mb-4 mr-3">
            <div
                id="basic-navbar-nav-creator"
                style={{ transform: 'translateX(-259%) !important' }}
                className={classNames('navbar-collapse-creator', 'collapse', { 'show': isCollappseCreatorEnter })}>
                <div id="omega-sidebar">
                    <div id="omega-sidebar-header" className="p-2 border-bottom d-flex align-items-center justify-content-between">
                        <Link href="/" locale={lng}>
                            <a>
                                <img width="110" src={assetURL('asset/logo/video_ac_250x57.png')} alt="videoAC" />
                            </a>
                        </Link>
                        <button className="btn btn-mobile-creator-close"><i className="fa fa-times" aria-hidden="true" /></button>
                    </div>
                    <div id="omega-sidebar-body">
                        <ul className="mobile-menu">
                            <li className="mobile-menu-item">
                                <div className="ac-mb-2">
                                    <a href="#">
                                        <img src={assetURL('asset/image/creator/banner11pt.jpeg')} alt="Google survey" className="w-100 mw-100 d-block mx-auto" />
                                    </a>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <Link href="/creator/upload">
                                            <a className="ac-btn-video text-white mt-3">
                                                動画を投稿する
                                            </a>
                                        </Link>
                                    </div>
                                </div>
                            </li>
                            <li className="mobile-menu-item">
                                <Link href={routes.creator.dashboard.href} >
                                    <a>
                                        マイページトップ
                                    </a>
                                </Link>
                            </li>
                            <li className="mobile-menu-item">
                                <Link href={routes.creator.list.href}>
                                    <a>
                                        投稿動画一覧
                                    </a>
                                </Link>
                            </li>
                            <li className="mobile-menu-item">
                                <a onClick={() => { setShowSettingAccountHeader(!showSettingAccountHeader) }}>
                                    会員情報設定
                                    {showSettingAccountHeader ? <i className="fas fa-angle-down" /> : <i className="fas fa-angle-right" />}
                                </a>
                            </li>
                            {showSettingAccountHeader && <ul className="m-0 p-0 d-flex align-items-center flex-wrap" style={{ listStyleType: 'none' }}>
                                <li className="w-100 border-bottom border-right">
                                    <Link href={routes.creator.account.edit.href}>
                                        <a href="" className="d-flex align-items-center p-2 text-decoration-none">
                                            会員情報編集
                                        </a>
                                    </Link>
                                </li>
                                <li className="w-100 border-bottom border-right">
                                    <Link href={routes.creator.bitcoin.href}>
                                        <a href="" className="d-flex align-items-center p-2 text-decoration-none">
                                            ビットコインアドレス設定
                                        </a>
                                    </Link>
                                </li>
                                <li className="w-100 border-bottom border-right">
                                    <Link href={routes.creator.ident.href}>
                                        <a href="" className="d-flex align-items-center p-2 text-decoration-none">
                                            本人確認書類提出
                                        </a>
                                    </Link>
                                </li>
                                <li className="w-100 border-bottom border-right">
                                    <Link href={routes.creator.bankInfo.href}>
                                        <a href="" className="d-flex align-items-center p-2 text-decoration-none">
                                            銀行口座情報
                                        </a>
                                    </Link>
                                </li>
                                <li className="w-100 border-bottom border-right">
                                    <Link href={routes.creator.account.leave.href}>
                                        <a className="d-flex align-items-center p-2 text-decoration-none">
                                            退会する
                                        </a>
                                    </Link>
                                </li>
                            </ul>}
                            <li className="mobile-menu-item">
                                <Link href={routes.creator.modelReleases.href}>
                                    <a>
                                        モデルリリース登録
                                    </a>
                                </Link>
                            </li>
                            <li className="mobile-menu-item">
                                <Link href={routes.creator.propertyRelease.href}>
                                    <a>
                                        プロパティリリース登録
                                    </a>
                                </Link>
                            </li>
                            <li className="mobile-menu-item">
                                <Link href={routes.creator.exchange.href}>
                                    <a>
                                        換金申請
                                    </a>
                                </Link>
                            </li>
                            
                            <li className="mobile-menu-item">
                                <Link href="https://help.freebie-ac.jp/category/5/写真クリエイター">
                                    <a target="_blank">
                                        ヘルプ
                                        <i className="fas fa-external-link-alt" />
                                    </a>
                                </Link>
                            </li>
                            <li className="mobile-menu-item">
                                <Link href="https://www.design-ac.net/">
                                    <a target="_blank">
                                        デザインテンプレート
                                        <i className="fas fa-external-link-alt" />
                                    </a>
                                </Link>
                            </li>
                            <li className="mobile-menu-item">
                                <Link href="https://ac-data.info/">
                                    <a target="_blank">
                                        ファイル転送・共有
                                        <i className="fas fa-external-link-alt" />
                                    </a>
                                </Link>
                            </li>
                            <li className="mobile-menu-item">
                                <Link href="https://blog.acworks.co.jp/">
                                    <a target="_blank">
                                        ブログ
                                        <i className="fas fa-external-link-alt" />
                                    </a>
                                </Link>
                            </li>
                            <li className="mobile-menu-item">
                                <Link href="https://uranai-ac.com/">
                                    <a target="_blank">
                                        今日の運勢
                                        <i className="fas fa-external-link-alt" />
                                    </a>
                                </Link>
                            </li>
                            <li className="mobile-menu-item">
                                <a href="#" onClick={() => signOut()}>ログアウト
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div id="omega-overlay" onClick={()=>{setIsCollappseCreatorEnter(false)}} className={classNames({ 'd-none': !isCollappseCreatorEnter })} />
        </Navbar>
    )
}

export default withI18n(HeaderNavBarCreator)