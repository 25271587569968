import { Alert, Button } from 'react-bootstrap'

import React from 'react'

const AlertRedirectError = ({ msg_err }: any) => {
  const [show, setShow] = React.useState(true)
  if (!msg_err) return null

  if (show) {
    return (
      <Alert variant="danger" className="mb-0" onClose={() => setShow(false)} dismissible>
        <Alert.Heading className="text-center">{msg_err}</Alert.Heading>
      </Alert>
    )
  }
  return <Button onClick={() => setShow(true)}>Show Alert</Button>
}

export default AlertRedirectError
