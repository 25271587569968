import { AppCommonProps } from 'types/app'
import React, { useState, useEffect } from 'react'
import { withI18n } from '~/hocs'
import { Dropdown } from 'react-bootstrap'
import axios from 'axios'

const DropdownApp = ({ }: AppCommonProps) => {
    const [listItem,setListItem] = useState('');
    const CustomToggle = React.forwardRef(({ children, onClick }: any, ref: any) => (
        <a
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault()
                onClick(e)
            }}
            className="ac-apps-button cursor-pointer ac-mr-1 ac-ml-1"
        >
            {children}
        </a>
    ))
    const CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }: any, ref: any) => {
            return (
                <div
                    ref={ref}
                    style={style}
                    className={className}
                    aria-labelledby={labeledBy}
                >
                    {children}
                </div>
            )
        },
    )
    useEffect(async () => {
        const listItemSite = await axios.get('https://accounts.ac-illust.com/get-list-apps', { params: { language: 'jp' } })
        setListItem(listItemSite.data);
    }, [])
    return (
        <div className="dropdown-section">
            <Dropdown>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                    <svg width="28" height="28" focusable="false" viewBox="0 0 24 24" fill="gray"><path d="M6,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM16,6c0,1.1 0.9,2 2,2s2,-0.9 2,-2 -0.9,-2 -2,-2 -2,0.9 -2,2zM12,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2z"></path></svg>
                </Dropdown.Toggle>

                <Dropdown.Menu as={CustomMenu} className="ac-dropdown-apps dropdown-menu p-0 m-0 border shadow-lg ac-mt-2" aria-labelledby="dropdownMenuButton">
                    <div dangerouslySetInnerHTML={{__html: listItem}} >

                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}
export default withI18n(DropdownApp)
