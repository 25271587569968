import React from 'react'
import { withI18n } from '~/hocs'
import { assetURL, buildHref } from '~/utils'
import { NextPage } from 'next'
import { AppCommonProps } from 'types/app'
import Link from 'next/link'
import { routes } from '~/router'
import { Navbar } from 'react-bootstrap'
import classNames from 'classnames'
import InnerHTML from 'dangerously-set-html-content'
import { useRouter } from 'next/router'


const HeaderNavBar: NextPage<AppCommonProps> = ({ t, lng, headerData, onNavCollapseEnter, isCollappseEnter, showCategoryHeader, setShowCategoryHeader }) => {
  const { asPath } = useRouter()
  const isCreatorPages = asPath.indexOf('/creator') === 0
    return (
        <Navbar bg="light" variant="light" expand="md" className="navbar-container-tophead">
        {!isCreatorPages && <button aria-controls="basic-navbar-nav" type="button" aria-label="Toggle navigation" className="navbar-toggler ml-4">
          <span className="navbar-toggler-icon" onClick={onNavCollapseEnter} /></button>}
        {headerData && <InnerHTML html={headerData.header} />}
        <div
          id="basic-navbar-nav"
          style={{ transform: 'translateX(-100%)', zIndex: 12 }}
          className={classNames('navbar-collapse', 'collapse', { 'show': isCollappseEnter })}>
          <div id="omega-sidebar">
            <div id="omega-sidebar-header" className="p-2 border-bottom d-flex align-items-center justify-content-between">
              <Link href="/" locale={lng}>
                <a>
                  <img width="110" src={assetURL('asset/logo/video_ac_250x57.png')} alt="videoAC" />
                </a>
              </Link>
              <button className="btn btn-mobile-close"><i className="fa fa-times" aria-hidden="true" /></button>
            </div>
            <div id="omega-sidebar-body">
              <ul className="mobile-menu">
                {headerData && <InnerHTML html={headerData.headerMobile} />}
                <li className="mobile-menu-item">
                  <a href="https://premium.photo-ac.com/premium/business" title="Corporate / multi-person plan" target="_blank">法人・複数名向けプラン
                  </a>
                </li>
                <li className="mobile-menu-item">
                  <a href="https://editor-ac.com/" title="Design template" target="_blank">デザインテンプレート
                    <i className="fas fa-external-link-alt" />
                  </a>
                </li>
                <li className="mobile-menu-item">
                  <a href="https://ac-data.info/" title="Design template" target="_blank">ファイル転送・共有
                    <i className="fas fa-external-link-alt" />
                  </a>
                </li>
                <li className="mobile-menu-item">
                  <a title="Design template" onClick={() => { setShowCategoryHeader(!showCategoryHeader) }}>動画カテゴリー
                    <i className="fas fa-angle-right" />
                  </a>
                </li>
                {/* Category */}
                {showCategoryHeader && <ul className="m-0 p-0 d-flex align-items-center flex-wrap" style={{ listStyleType: 'none' }}>
                  {headerData.categories.data && headerData.categories.data.map((item: any) => {
                    return <li className="w-50 border-bottom border-right">
                      <Link href={buildHref({
                        pathname: routes.category.href,
                        query: { id: item.id, name: encodeURIComponent(item.name) },
                        lng,
                      })}>
                        <a href="" className="d-flex align-items-center p-2 text-decoration-none">
                          {item.name}
                        </a>
                      </Link>
                    </li>
                  })}
                </ul>}
                {/* End category */}
                <li className="mobile-menu-item">
                  <a href={buildHref({
                    pathname: routes.discovery.href,
                    query: { page: 1, sort_by: '-id' },
                    // query: { id, slug, search: disableSearch ? undefined : search },
                    lng,
                  })} target="_blank">新着動画
                  </a>
                </li>
                <li className="mobile-menu-item"><a href="https://help.freebie-ac.jp" title="FAQ / Help" target="_blank">{t('faq')}</a></li>
                <li className="mobile-menu-item"><a className='ac-text-blue' href="https://test.video-ac.com/creatorP/reg" title="クリエイター投稿（新規登録）" target="_blank">クリエイター投稿（新規登録）</a></li>
                <li className="mobile-menu-item"><a className='ac-text-blue' href="https://accounts.ac-illust.com/login?lang=jp&serviceURL=https%3A%2F%2Fvideo-ac.com%2Fapi%2Fauth%2Fsignin%3Fredirect%3Dhttps%3A%2F%2Fvideo-ac.com%2F"
                  title="クリエイターログイン" target="_blank">クリエイターログイン</a></li>
                <li className="mobile-menu-item"><a href="https://uranai-ac.com" title="今日の運勢" target="_blank">今日の運勢<i className="fas fa-external-link-alt" /></a></li>
              </ul>

            </div>
          </div>
        </div>
        <div id="omega-overlay" className={classNames({ 'd-none': !isCollappseEnter })} />

      </Navbar>
    )
}

export default withI18n(HeaderNavBar)