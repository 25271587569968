import React from 'react'
import { withI18n } from '~/hocs'
import { NextPage } from 'next'
import { AppCommonProps } from 'types/app'
import config from '~/config'
import moment from 'moment'


const HeaderSearch: NextPage<AppCommonProps> = ({ t, asPath, isPremium, isAuthen, nextAuthStatus, headerData, context, profile }) => {
    return (
        <>
            {asPath !== '/' && isAuthen && nextAuthStatus !== 'authenticated' ? <>
                {(profile?.premium_info?.pause_end_date && moment(profile?.premium_info.pause_end_date, "YYYY-MM-DD").valueOf() > moment().valueOf() || !isPremium) && <div className="d-block d-xl-none d-sm-none mb-3 position-absolute premium-header">
                    <div className="bg-f1f1f1 text-center p-1 font-size-12">
                        <a href={config.premiumUrl} target="_blank" className="ac-text-blue" data-toggle="tooltip" title="" data-original-title="プレミアム会員登録">
                            <img className="mw-100 lazy align-middle mt-n1 loaded" alt="プレミアム会員登録" height="15" src="https://jp.static.photo-ac.com/assets/img/responsive/ico_premium.png" data-ll-status="loaded" />
                            <span className="align-middle">プレミアム会員登録</span>
                        </a>
                        <span className="align-middle">で14個の特典をゲット</span>
                    </div>
                </div>}
                <div
                    className="flex-center flex-grow-1 h-100 mt-2 mt-md-0 mx-md-2 search-container-header">
                    <context.SearchPanel value={headerData.search} />
                </div>
            </> : <div
                className="flex-center flex-grow-1 h-100 mt-2 mt-md-0 mx-md-2">
                <context.SearchPanel value={headerData.search} />
            </div>}
        </>
    )
}

export default withI18n(HeaderSearch)