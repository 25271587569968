import React from 'react'
import { withI18n } from '~/hocs'
import { assetURL } from '~/utils'
import { NextPage } from 'next'
import { AppCommonProps } from 'types/app'
import Link from 'next/link'
import { routes } from '~/router'
import moment from 'moment'

const HeaderInfoSideBar: NextPage<AppCommonProps> = ({ t, lng, setShowInfoSideBar, isPremium, profile }) => {
  return (
    <>
      <div id="omega-overlay" onClick={() => { setShowInfoSideBar(false) }} />
      <div className="position-fixed top-0 right-0 h-100 w-auto bg-white ac-z-index-99999 active" style={{ display: "block" }}>
        <div className="max-width-250">
          <div className="dropdown-item ac-px-2 ac-py-1 border-bottom">
            {
              isPremium
                ? <span className="d-flex align-items-center">
                  {profile?.premium_info?.pause_end_date && moment(profile?.premium_info?.pause_end_date, "YYYY-MM-DD").valueOf() > moment().valueOf() ? <></> : <img className="d-block mw-100 mr-2" src={assetURL('asset/image/crown.png')} width="20" />}
                  <span>
                  {profile?.premium_info?.pause_end_date && moment(profile?.premium_info?.pause_end_date, "YYYY-MM-DD").valueOf() > moment().valueOf() ? t('premium_paused') : (profile?.premium_info?.role ? (profile?.premium_info?.role?.id === 1 ? t('premium_contractor') : t('premium_member')) : t('premium_individual'))}
                  </span>
                </span>
                : <span className="d-block">{t('free_members')}</span>
            }
            <small className="d-block">{
              profile.nick_name
                ? <>{profile?.nick_name.length < 20 ? profile?.nick_name : profile?.nick_name.substring(0, 20) + '...'}さん</>
                : <>{profile?.email.substring(0, 1) + '**********' + profile?.email.substring(profile?.email.length - 1, profile?.email.length)}さん</>
            }</small>
          </div>
          <Link href={routes.user.profile.href} locale={lng}>
            <a className="dropdown-item ac-px-2 ac-py-1 border-bottom" href={routes.user.dashboard.href}><i className="fa fa-exclamation-circle text-warning mr-2"></i>
              {t('my_page')}</a>
          </Link>
          <Link href={routes.user.downloads.href} locale={lng}>
            <a className="dropdown-item ac-px-2 ac-py-1 border-bottom d-flex align-items-center" href="/user/downloads/"><i className="fa fa-download mr-2" aria-hidden="true"></i>ダウンロード履歴</a>
          </Link>
          <Link href="https://help.freebie-ac.jp/" locale={lng}>
            <a className="dropdown-item ac-px-2 ac-py-1 border-bottom" href="https://help.freebie-ac.jp" target="_blank">よくある質問</a>
          </Link>
          <Link href={routes.user.presents.href} locale={lng}>
            <a className="dropdown-item ac-px-2 ac-py-1 border-bottom" href="https://test.photo-ac.com//auth/logout">プレミアム会員特典</a>
          </Link>
          <span className="dropdown-item ac-px-2 ac-py-1 border-bottom" onClick={() => { location.href = `/api/auth/signout?redirect=${window.location.href}` }}>{t('logout')}</span>
        </div>
      </div>
    </>
  )
}

export default withI18n(HeaderInfoSideBar)