import React from 'react'
import { withI18n } from '~/hocs'
import { assetURL, getAvatar } from '~/utils'
import { NextPage } from 'next'
import { AppCommonProps } from 'types/app'
import moment from 'moment'

const HeaderPremium: NextPage<AppCommonProps> = ({ isPremium,profile, setShowInfoSideBar }) => {
  return (
    <div>
      {isPremium && !(profile?.premium_info?.pause_end_date && moment(profile?.premium_info.pause_end_date, "YYYY-MM-DD").valueOf() > moment().valueOf() || !isPremium) && <img className="mr-2" src={assetURL('asset/image/crown.png')} width="30" />}
      <img src={getAvatar(profile)} alt="avatar" width="40" />
      <i className="fa-solid fa-chevron-right ml-3" style={{fontSize: "10px"}} onClick={()=>{setShowInfoSideBar(true)}}></i>
    </div>
  )
}

export default withI18n(HeaderPremium)